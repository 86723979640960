<template>
  <div
    ref="workHistory"
    class="shadow overflow-hidden sm:rounded-lg"
  >
    <div class="px-4 py-5 bg-white sm:p-6">
      <div class="space-y-8 divide-y divide-dashed -mt-8">
        <div
          v-for="(entry, index) in workHistory"
          :key="index"
          class="grid md:grid-cols-8 gap-6 text-sm pt-8 relative"
        >
          <a
            v-if="workHistory.length > 1"
            href="javascript:;"
            class="text-red-500 hover:text-red-400 duration-150 focus:outline-none absolute top-0 right-0 mt-5"
            @click="deleteWorkHistory(index)"
          >
            <span class="hidden sm:inline-block">Delete</span>

            <Icon
              view-box="0 0 24 24"
              class="w-4 h-4 ml-1"
            >
              <Bin />
            </Icon>
          </a>
          <div class="col-span-8 md:col-span-4">
            <label class="block">
              <SearchJobRoles
                :v="v.$each[index].title"
                @selected="handleRoleSelection($event, index)"
              />

            </label>
            <ErrorsInline v-if="hasErrors(index)">
              <span v-if="!v.$each[index].title.required">This field is required</span>
            </ErrorsInline>
          </div>

          <div class="col-span-8 md:col-span-4">
            <label class="block">
              <span class="font-medium text-gray-700">Employer</span>
              <input
                v-model="v.$each[index].employer.$model"
                type="text"
                class="form-input mt-1 block w-full rounded-lg"
              >
            </label>
            <ErrorsInline v-if="hasErrors(index)">
              <span v-if="!v.$each[index].employer.required">This field is required</span>
            </ErrorsInline>
          </div>

          <div class="col-span-4">
            <p class="font-medium text-gray-700">
              From:
            </p>
          </div>
          <div class="col-span-4">
            <p class="font-medium text-gray-700">
              To:
            </p>
          </div>

          <div class="col-span-4 md:col-span-2">
            <label
              for="startMonth"
              class="block font-medium text-gray-700"
            >
              Month
            </label>
            <div class="mt-1">
              <select
                id="startMonth"
                v-model="v.$each[index].start.month.$model"
                name="startMonth"
                class="form-select rounded-lg w-full text-sm"
              >
                <option
                  v-for="(month, index) in generatedMonths"
                  :key="index"
                  :value="month.value"
                >
                  {{ month.text }}
                </option>
              </select>
            </div>
            <ErrorsInline v-if="hasErrors(index)">
              <span v-if="!v.$each[index].start.month.required">This field is required</span>
            </ErrorsInline>
          </div>

          <div class="col-span-4 md:col-span-2 md:col-start-3">
            <label
              for="startYear"
              class="block font-medium text-gray-700"
            >
              Year
            </label>
            <div class="mt-1">
              <select
                id="startYear"
                v-model="v.$each[index].start.year.$model"
                name="startYear"
                class="form-select rounded-lg w-full text-sm"
              >
                <option
                  v-for="(year, index) in generatedYears"
                  :key="index"
                  :value="year.value"
                >
                  {{ year.text }}
                </option>
              </select>
            </div>
            <ErrorsInline v-if="hasErrors(index)">
              <span v-if="!v.$each[index].start.year.required">This field is required</span>
            </ErrorsInline>
          </div>

          <div class="col-span-4 md:col-span-2 md:col-start-5">
            <label
              for="endMonth"
              class="block font-medium text-gray-700"
            >
              Month
            </label>
            <div class="mt-1">
              <select
                id="endMonth"
                v-model="v.$each[index].end.month.$model"
                name="endMonth"
                class="form-select rounded-lg w-full text-sm"
              >
                <option
                  v-for="(month, index) in generatedMonths"
                  :key="index"
                  :value="month.value"
                >
                  {{ month.text }}
                </option>
              </select>
            </div>
            <ErrorsInline v-if="hasErrors(index)">
              <span v-if="!v.$each[index].end.month.required && !v.$each[index].current.$model">This field is required</span>
            </ErrorsInline>
          </div>

          <div class="col-span-4 md:col-span-2 md:col-start-7">
            <label
              for="endYear"
              class="block font-medium text-gray-700"
            >
              Year
            </label>
            <div class="mt-1">
              <select
                id="endYear"
                v-model="v.$each[index].end.year.$model"
                name="endYear"
                class="form-select rounded-lg w-full text-sm "
              >
                <option
                  v-for="(year, index) in generatedYears"
                  :key="index"
                  :value="year.value"
                >
                  {{ year.text }}
                </option>
              </select>
            </div>
            <ErrorsInline v-if="hasErrors(index)">
              <span v-if="!v.$each[index].end.year.required && !v.$each[index].current.$model">This field is required</span>
            </ErrorsInline>
          </div>

          <section class="col-span-6">
            <span class="flex">
              <label class="flex items-center">
                <input
                  v-model="v.$each[index].current.$model"
                  type="checkbox"
                  class="form-checkbox rounded"
                  @change="isCurrentJob(v.$each[index].current.$model, index)"
                />

                <span class="ml-2 font-medium text-gray-700">
                  I still work here
                </span>
              </label>
            </span>
          </section>

          <section class="col-span-8">
            <label
              for="highlights"
              class="flex items-center mb-1 font-medium text-gray-700"
            >
              Highlights &amp; Achievements
            </label>

            <textarea
              id="highlights"
              v-model="v.$each[index].highlights.$model"
              class="form-textarea w-full focus:border-gray-800 focus:shadow-none resize-none rounded-lg"
              rows="4"
              @focus="$event.target.select()"
            />
          </section>
        </div>
      </div>
      <div class="sm:col-span-8 mt-8">
        <button
          class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg w-full hover:border-gray-400 hover:text-gray-500 text-gray-400 duration-150 ease-out focus:outline-none"
          @click="addWorkHistory"
        >
          <div class="space-y-1 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="mx-auto h-6 w-6 duration-150 ease-out"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
              />
            </svg>
            <div class="flex text-sm text-gray-600">
              <span>Add another job</span>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Bin from '@components/Icons/Bin'
import ErrorsInline from '@components/ErrorsInline'
import { generatedYears, generatedMonths } from '@utils/yearsMonths'
import SearchJobRoles from '@components/Jobs/SearchJobRoles'

export default {
  components: {
    ErrorsInline,
    Icon,
    Bin,
    SearchJobRoles
  },

  props: {
    workHistory: {
      type: Array,
      default: null
    },
    v: {
      type: Object,
      default: null
    }
  },

  computed: {
    generatedYears,
    generatedMonths
  },

  methods: {
    addWorkHistory() {
      this.$emit('addWorkHistory')
    },

    deleteWorkHistory(workHistoryIndex) {
      this.$emit('deleteWorkHistory', workHistoryIndex)
    },

    isCurrentJob(checked, index) {
      this.$emit('isCurrentJob', { checked, index })
    },

    handleRoleSelection(jobRole, index) {
      let jobRoleObject

      if (jobRole && jobRole.name) {
        // The jobRole has been chosen from our list
        jobRoleObject = { title: jobRole.name, index }
      } else {
        jobRoleObject = { title: jobRole, index }
      }
      this.$emit('chooseJobTitle', jobRoleObject)
    },

    hasErrors(index) {
      return this.v.$each[index].$anyError
    }
  }
}
</script>
