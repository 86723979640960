<template>
  <div
    ref="educationHistory"
    class="shadow overflow-hidden sm:rounded-lg"
  >
    <div class="px-4 py-5 bg-white sm:p-6">
      <div class="space-y-8 divide-y divide-dashed -mt-8">
        <div
          v-for="(entry, index) in educationHistory"
          :key="index"
          class="grid md:grid-cols-8 gap-6 text-sm pt-8 relative"
        >
          <a
            v-if="educationHistory.length > 1"
            href="javascript:;"
            class="text-red-500 hover:text-red-400 duration-150 focus:outline-none absolute top-0 right-0 mt-5"
            @click="deleteEducationHistory(index)"
          >
            <span class="hidden sm:inline-block">Delete</span>

            <Icon
              view-box="0 0 24 24"
              class="w-4 h-4 ml-1"
            >
              <Bin />
            </Icon>
          </a>

          <div class="col-span-8 md:col-span-4">
            <label class="block">
              <span class="font-medium text-gray-700">Institute</span>

              <SearchSelectMenu
                index-name="institutes"
                :search-client="searchClient"
                :v="v.$each[index].institute"
                @selected="$emit('chooseInstitute', { institute: $event, index })"
              />
            </label>
            <ErrorsInline v-if="hasErrors(index)">
              <span v-if="!v.$each[index].institute.required">This field is required</span>
            </ErrorsInline>
          </div>

          <div class="col-span-8 md:col-span-4">
            <label class="block">
              <span class="font-medium text-gray-700">Course</span>

              <SearchSelectMenu
                index-name="courses"
                :search-client="searchClient"
                :v="v.$each[index].course"
                @selected="$emit('chooseCourse', { course: $event, index })"
              />
            </label>
            <ErrorsInline v-if="hasErrors(index)">
              <span v-if="!v.$each[index].course.required">This field is required</span>
            </ErrorsInline>
          </div>

          <!-- <p class="col-span-6 font-medium gap-0">
                    From
                  </p> -->

          <div class="col-span-4 md:col-span-2">
            <label
              for="classification"
              class="block font-medium text-gray-700"
            >
              Classification
            </label>
            <div class="mt-1">
              <select
                id="classification"
                v-model="v.$each[index].type.$model"
                name="classification"
                class="form-select rounded-lg w-full text-sm"
              >
                <option
                  v-for="(classification, index) in classifications"
                  :key="index"
                  :value="classification.value"
                >
                  {{ classification.text }}
                </option>
              </select>
              <ErrorsInline v-if="hasErrors(index)">
                <span v-if="!v.$each[index].type.required">This field is required</span>
              </ErrorsInline>
            </div>
          </div>

          <div class="col-span-4 md:col-span-2 md:col-start-3">
            <label
              for="grade"
              class="block font-medium text-gray-700"
            >
              Grade
            </label>
            <div class="mt-1">
              <select
                id="grade"
                v-model="v.$each[index].grade.$model"
                name="grade"
                class="form-select rounded-lg w-full text-sm"
              >
                <option
                  v-for="(grade, index) in grades"
                  :key="index"
                  :value="grade.value"
                >
                  {{ grade.text }}
                </option>
              </select>
              <ErrorsInline v-if="hasErrors(index)">
                <span v-if="!v.$each[index].grade.required">This field is required</span>
              </ErrorsInline>
            </div>
          </div>

          <div class="col-span-4 md:col-span-2 md:col-start-1">
            <label
              for="country"
              class="font-medium text-gray-700"
            >
              Start Year
            </label>
            <div class="mt-1">
              <select
                id="educationStartYear"
                v-model="v.$each[index].startYear.$model"
                name="educationStartYear"
                class="form-select rounded-lg w-full text-sm"
              >
                <option
                  v-for="(year, index) in generatedYears"
                  :key="index"
                  :value="year.value"
                >
                  {{ year.text }}
                </option>
              </select>
              <ErrorsInline v-if="hasErrors(index)">
                <span v-if="!v.$each[index].startYear.required">This field is required</span>
              </ErrorsInline>
            </div>
          </div>

          <div class="col-span-4 md:col-span-2 md:col-start-3">
            <label
              for="educationEndYear"
              class="block font-medium text-gray-700"
            >
              End Year
            </label>
            <div class="mt-1">
              <select
                id="educationEndYear"
                v-model="v.$each[index].endYear.$model"
                name="educationEndYear"
                class="form-select rounded-lg w-full text-sm"
              >
                <option
                  v-for="(year, index) in generatedYearsWithFutureYears(7)"
                  :key="index"
                  :value="year.value"
                >
                  {{ year.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:col-span-8 mt-8">
        <button
          class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg w-full hover:border-gray-400 hover:text-gray-500 text-gray-400 duration-150 ease-out"
          @click="addEducationHistory"
        >
          <div class="space-y-1 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="mx-auto h-6 w-6 duration-150 ease-out"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
              />
            </svg>
            <div class="flex text-sm text-gray-600">
              <span>Add another</span>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchSelectMenu from '@components/Algolia/SearchSelectMenu'
import Icon from '@components/Icons/Icon'
import Bin from '@components/Icons/Bin'
import ErrorsInline from '@components/ErrorsInline'

import { generatedYears } from '@utils/yearsMonths'
import { classifications, grades } from '@utils/education'
import { searchClient } from '@utils/algoliaClient'

export default {
  components: {
    ErrorsInline,
    Icon,
    Bin,
    SearchSelectMenu
  },

  props: {
    educationHistory: {
      type: Array,
      default: null
    },
    v: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      searchClient,
      classifications,
      grades
    }
  },

  computed: {
    generatedYears
  },

  methods: {
    addEducationHistory() {
      if (this.v.$anyError) {
        return
      }
      this.$emit('addEducationHistory')
    },

    deleteEducationHistory(educationIndex) {
      this.$emit('deleteEducationHistory', educationIndex)
    },

    hasErrors(index) {
      return this.v.$each[index].$anyError
    },

    generatedYearsWithFutureYears(extraYears) {
      if (!extraYears) {
        extraYears = 0
      }
      return generatedYears(extraYears)
    }
  }
}
</script>
