<template>
  <div
    ref="skills"
    class="shadow overflow-hidden sm:rounded-lg"
  >
    <div class="px-4 py-5 bg-white sm:p-6">
      <div class="grid md:grid-cols-4 gap-6 text-sm md:divide-x md:divide-dashed">
        <div class="col-span-4 md:col-span-2 order-2 md:order-1">
          <label class="block">
            <p class="mb-1 font-medium text-gray-700">Search skills</p>

            <ais-instant-search
              index-name="skills_en"
              :search-client="searchClient"
            >

              <SearchInput
                v-show="searchedSkills !== null"
                @update="customSkill = $event"
                @keydown.enter.prevent="addSkill(customSkill)"
              />

              <ais-state-results>
                <template slot-scope="{ state: { query } }">
                  <ais-hits
                    v-show="query.length > 0"
                    :transform-items="transformSkills"
                  >
                    <ul
                      slot-scope="{ items }"
                      class="text-sm flex flex-wrap -mx-2 mt-4"
                    >
                      <li
                        v-for="item in filterSearchedSkills(items)"
                        :key="item.objectID"
                        class="p-2"
                      >
                        <button
                          type="button"
                          class="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-gray-200 text-gray-700 hover:bg-gray-400 focus:outline-none duration-150 ease-out"
                          @click="addSkill(item)"
                        >
                          <span class="sr-only">Select {{ item }}</span>
                          <span class="mr-2">{{ item }}</span>
                          <svg
                            class="h-2 w-2 transform rotate-45"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-width="1.5"
                              d="M1 1l6 6m0-6L1 7"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </ais-hits>
                </template>
              </ais-state-results>

            </ais-instant-search>
          </label>
        </div>

        <div class="col-span-4 md:col-span-2 order-1 md:order-2 md:pl-6">
          <p class="font-medium text-gray-700">
            Selected skills
          </p>
          <p
            v-if="!skills.length"
            class="text-xs text-gray-600"
          >
            Your selected skills will appear here.
          </p>
          <ul class="text-sm flex flex-wrap -mx-2">
            <li
              v-for="(skill, index) in skills"
              :key="skill"
              class="p-2"
            >
              <button
                type="button"
                class="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-secondary text-white hover:bg-secondary-400 duration-150 ease-out"
                @click="deleteSkill(index)"
              >
                <span class="sr-only">Select {{ skill }}</span>
                <span class="mr-2">{{ skill }}</span>
                <svg
                  class="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <ErrorsInline
        v-if="v.$anyError"
        class="relative"
      >
        <span v-if="!v.required || !v.minLength">Please select at least {{ v.$params.minLength.min }} skills</span>
      </ErrorsInline>
    </div>
  </div>
</template>

<script>
import SearchInput from '@components/Algolia/SearchInput'
import ErrorsInline from '@components/ErrorsInline'

import { searchClient } from '@utils/algoliaClient'

export default {
  components: {
    SearchInput,
    ErrorsInline
  },

  props: {
    v: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      searchClient,
      searchedSkills: null,
      skills: [],
      customSkill: ''
    }
  },

  watch: {
    skills: {
      deep: true,
      handler() {
        this.$emit('selected', this.skills)
      }
    },

    v: {
      deep: true,
      immediate: true,
      handler() {
        this.skills = this.v.$model
      }
    }
  },

  methods: {
    transformSkills(skills) {
      this.searchedSkills = skills
      return skills
    },

    filterSearchedSkills(skills) {
      const skillNames = skills.map(skill => skill.name)

      return skillNames.filter(skill => {
        return !this.skills.includes(skill)
      })
    },

    addSkill(skill) {
      if (!skill) {
        return
      }
      this.skills.push(skill)
    },

    deleteSkill(skillIndex) {
      this.skills = this.skills.filter((_, index) => index !== skillIndex)
    }
  }

}
</script>
