<template>
  <div class="flex items-center">
    <ErrorsInline
      v-if="uploadError"
      class="relative mr-4"
    >
      <span>{{ uploadError }}</span>
    </ErrorsInline>
    <label
      for="cv-upload"
      class="duration-150 rounded bg-transparent hover:text-gray-700 border border-gray-500 px-4 py-3 cursor-pointer inline-flex"
    >
      <svg
        v-if="processing"
        class="animate-spin h-5 w-5 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 mr-2"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
        <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
      </svg>
      <span class="text-sm">Import CV</span>
      <input
        id="cv-upload"
        type="file"
        :disabled="processing"
        class="sr-only"
        @click="uploadError = ''"
        @change="uploadCV($event.target.files[0])"
      >
    </label>
  </div>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import candidateWizardApi from '@api/candidateWizard'

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    // reader.result starts with `data:*/*;base64,`, which is not valid
    // base64, so we split after the first comma.
    reader.onload = () => resolve(reader.result.split(',')[1])
    reader.onerror = error => reject(error)
  })
}

export default {
  components: {
    ErrorsInline
  },

  data() {
    return {
      processing: false,
      uploadError: ''
    }
  },

  methods: {
    uploadCV(file) {
      this.processing = true

      getBase64(file)
        .then(cvFileBase64 => {
          this.parseCV({ document: cvFileBase64 })
        })
        .catch(error => {
          this.processing = false
          throw error
        })
    },

    parseCV(data) {
      return candidateWizardApi.parseCV(data)
        .then(parsedCV => {
          this.processing = false
          this.$emit('uploaded', parsedCV)
        })
        .catch(error => {
          this.processing = false
          this.uploadError = 'Apologies. Something went wrong. Please try again.'
          throw error
        })
    }
  }
}
</script>
