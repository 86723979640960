<template>
  <div
    ref="employment"
    class="space-y-8"
  >
    <fieldset>
      <div class="grid md:grid-cols-2 gap-4 items-center">
        <div class="col-span-1">
          <legend class="font-medium text-gray-700">
            What is your job search status?
          </legend>
        </div>
        <div class="col-start-2">
          <label
            class="block relative"
          >
            <select
              v-model="employmentStatus.availability"
              class="form-select block w-full mt-1 rounded-lg"
            >
              <option
                v-for="(status, index) in statuses"
                :key="index"
                :value="status.value"
              >
                {{ status.text }}
              </option>
            </select>
          </label>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div class="grid md:grid-cols-2 gap-4 items-center">
        <div class="col-span-1">
          <legend class="font-medium text-gray-700">
            What is your notice period?
          </legend>
        </div>
        <div class="col-start-2">
          <label
            class="block relative"
          >
            <select
              v-model="employmentStatus.noticePeriod"
              class="form-select block w-full mt-1 rounded-lg"
            >
              <option
                v-for="(noticePeriod, index) in noticePeriods"
                :key="index"
                :value="noticePeriod.value"
              >
                {{ noticePeriod.text }}
              </option>
            </select>
          </label>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend class="font-medium text-gray-700">
        What are your minimum salary expectations?
      </legend>

      <ErrorsInline
        v-if="v.$anyError"
        class="relative"
      >
        <span v-if="atLeastOneOptionChosen">Please select at least one option</span>
      </ErrorsInline>

      <ul class="space-y-6 mt-4">
        <li>
          <label class="flex items-center">
            <input
              v-model="v.permanent.preference.$model"
              type="checkbox"
              class="form-checkbox rounded"
            >
            <span class="ml-2 text-gray-800">
              Permanent
            </span>
          </label>

          <label
            v-if="v.permanent.preference.$model"
            class="block relative mt-2 md:w-1/2"
          >
            <span class="text-gray-800 text-xs">Minimum salary per year</span>
            <select
              v-model="v.permanent.minPay.$model"
              class="form-select block w-full mt-1 rounded-lg"
            >
              <option
                v-for="(salary, index) in salaries"
                :key="index"
                :value="salary.value"
              >
                {{ salary.text }}
              </option>
            </select>
          </label>

          <!-- <ErrorsInline class="relative">
            <span v-if="!v.permanent.minPay.required">Please select a salary</span>
          </ErrorsInline> -->
        </li>

        <li>
          <label class="flex items-center">
            <input
              v-model="v.contract.preference.$model"
              value="contract"
              type="checkbox"
              class="form-checkbox rounded"
            >
            <span class="ml-2 text-gray-800">
              Contract
            </span>
          </label>

          <label
            v-if="v.contract.preference.$model"
            class="block relative mt-2 md:w-1/2"
          >
            <span class="text-gray-800 text-xs">Minimum rate per day</span>
            <select
              v-model="v.contract.minPay.$model"
              class="form-select block w-full mt-1 rounded-lg"
            >
              <option
                v-for="(rate, index) in dayRates"
                :key="index"
                :value="rate.value"
              >
                {{ rate.text }}
              </option>
            </select>
          </label>
          <!--
          <ErrorsInline class="relative">
            <span v-if="!v.contract.minPay.required">Please select a rate</span>
          </ErrorsInline> -->
        </li>

        <li class="flex">
          <label class="flex items-center">
            <input
              v-model="v.internship.preference.$model"
              value="internship"
              type="checkbox"
              class="form-checkbox rounded"
            >
            <span class="ml-2 text-gray-800">
              Internship
            </span>
          </label>
        </li>
      </ul>
    </fieldset>
  </div>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import { salaries, dayRates } from '@data/earnings'

export default {
  components: {
    ErrorsInline
  },

  props: {
    employmentStatus: {
      type: Object,
      required: true
    },
    employment: {
      type: Object,
      required: true
    },
    v: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      salaries,
      dayRates,
      // availability: 'Available',
      // noticePeriod: 'Now',

      statuses: [
        {
          text: 'Available',
          value: 'Available'
        },
        {
          text: 'Open to offers',
          value: 'Open'
        },
        {
          text: 'Unavailable',
          value: 'Unavailable'
        }
      ],

      noticePeriods: [
        {
          text: 'Available immediately',
          value: 'Now'
        },
        {
          text: '1 month',
          value: '1 month'
        },
        {
          text: '2 months',
          value: '2 months'
        },
        {
          text: '3+ months',
          value: '3 months'
        }
      ]
    }
  },

  computed: {
    atLeastOneOptionChosen() {
      return !this.v.permanent.preference.required &&
            !this.v.contract.preference.required &&
            !this.v.internship.preference.required
    }
  }
}
</script>
