<template>
  <div
    ref="roleExperience"
    class="shadow overflow-hidden sm:rounded-lg"
  >
    <div class="px-4 py-5 bg-white sm:p-6">
      <div class="grid md:grid-cols-4 gap-6 text-sm md:divide-x md:divide-dashed">
        <div class="col-span-4 md:col-span-2 order-2 md:order-1">
          <SearchJobRoles
            label="Search roles"
            :v="v"
            :disable-dropdown="true"
            @searchedRoles="searchedRoles = $event"
            @keydownEnter="addRole($event)"
          />

          <ul
            v-show="limitedSearchedRoles"
            class="text-sm flex flex-wrap -mx-2 mt-4"
          >
            <li
              v-for="(item, itemIdx) in limitedSearchedRoles"
              :key="itemIdx"
              class="p-2"
            >
              <button
                type="button"
                class="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-gray-200 text-gray-700 hover:bg-gray-400 focus:outline-none duration-150 ease-out"
                @click="addRole(item)"
              >
                <span class="sr-only">Select {{ item.name }}</span>
                <span class="mr-2 truncate">{{ item.name }}</span>
                <svg
                  class="h-2 w-2 transform rotate-45"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </div>

        <div class="col-span-4 md:col-span-2 order-1 md:order-2 md:pl-6">
          <p class="font-medium text-gray-700">
            Years of experience
          </p>
          <p
            v-if="!roles.length"
            class="text-xs text-gray-600"
          >
            Your selected roles will appear here.
          </p>
          <ul class="text-sm space-y-8 mt-4">
            <li
              v-for="(role, index) in roles"
              :key="index"
              class="flex "
            >
              <button
                class="inline-flex mr-2 text-gray-600 focus:outline-none"
                @click="deleteRole(index)"
              >
                x
              </button>
              <RangeSlider
                class="w-full"
                :max="50"
                :title="role.name"
                :saved-value="role.years"
                metric="years"
                @onChange="changeExperienceYears(role, $event, index)"
              />
            </li>
          </ul>
        </div>
      </div>

      <ErrorsInline
        v-if="v.$anyError"
        class="relative"
      >
        <span v-if="!v.required || !v.minLength">Please select at least {{ v.$params.minLength.min }} role</span>
      </ErrorsInline>
    </div>
  </div>
</template>

<script>
// import SearchInput from '@components/Algolia/SearchInput'
import RangeSlider from '@components/RangeSlider'
import ErrorsInline from '@components/ErrorsInline'
import SearchJobRoles from '@components/Jobs/SearchJobRoles'

import Vue from 'vue'

export default {
  components: {
    RangeSlider,
    ErrorsInline,
    SearchJobRoles
  },

  props: {
    v: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      searchedRoles: null,
      roles: this.v.$model,
      customRole: '',
      limit: 10
    }
  },

  computed: {
    limitedSearchedRoles() {
      if (this.searchedRoles && this.searchedRoles.length) {
        return this.searchedRoles.slice(0, this.limit)
      }
    }
  },

  watch: {
    roles: {
      deep: true,
      handler() {
        this.$emit('selected', this.roles)
      }
    }
  },

  methods: {
    changeExperienceYears(role, years, roleIndex) {
      Vue.set(this.roles[roleIndex], 'years', years)
    },

    handleChange(value, checked) {
      if (checked) {
        this.roles = this.roles.concat({ name: value })
      } else {
        this.roles = this.roles.filter(x => x.name !== value)
      }
    },

    addRole(role) {
      if (!role) {
        return
      }
      this.roles.push({
        name: role.name ? role.name : role,
        years: 0
      })
    },

    deleteRole(roleIndex) {
      this.roles = this.roles.filter((_, index) => index !== roleIndex)
    }
  }
}
</script>
