<template>
  <FetchCandidate :with-profile="true">
    <template slot-scope="{ candidate, profile, loading }">
      <div class="bg-gray-100 flex-grow">
        <div class="w-full max-w-screen-xl relative mx-auto py-8 px-4 rounded-lg relative">
          <transition
            enter-active-class="transition-all duration-500 ease-out"
            leave-active-class="transition-all duration-150 ease-in"
            enter-class="opacity-0"
            leave-class="opacity-100"
            enter-to-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="loading"
              class="absolute inset-0"
              style="margin-top: 100px;"
            >
              <Loader />
            </div>
            <div v-if="candidate && profile">
              <div class="md:flex md:justify-between md:items-center mb-8">
                <Breadcrumbs
                  :breadcrumbs="breadcrumbs"
                />
                <div class="flex md:justify-between mt-6 md:mt-0 space-x-6">
                  <UploadCVButton @uploaded="cv = $event" />
                  <!-- <LinkedInButton /> -->
                </div>
              </div>
              <EditProfile
                :candidate="candidate"
                :profile="profile"
                :cv="cv"
              />
            </div>
          </transition>
        </div>
      </div>
    </template>
  </FetchCandidate>
</template>

<script>
import FetchCandidate from '@components/CandidateWizard/FetchCandidate'
import EditProfile from '@components/CandidateWizardProfile/EditProfile'
import Breadcrumbs from '@components/Breadcrumbs'
import Loader from '@components/Loader'
import UploadCVButton from '@components/CandidateWizardProfile/UploadCVButton'
// import LinkedInButton from '@components/CandidateWizardProfile/LinkedInButton'

export default {
  components: {
    FetchCandidate,
    EditProfile,
    Loader,
    Breadcrumbs,
    UploadCVButton
    // LinkedInButton
  },

  data() {
    return {
      cv: null
    }
  },

  computed: {
    breadcrumbs() {
      return [
        {
          name: 'Dashboard',
          link: 'candidate-wizard-dashboard'
        },
        {
          name: 'Profile',
          link: 'candidate-wizard-profile-edit'
        }
      ]
    }
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.launchReloadWarning)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.launchReloadWarning)
    })
  },

  methods: {
    /**
     * Launch reload page generic browser alert
     */
    launchReloadWarning(event) {
      event.preventDefault()
      // Chrome requires returnValue to be set
      event.returnValue = ''
    }
  }
}
</script>
