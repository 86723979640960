export const classifications = [
  { text: 'AP', value: 'AP' },
  { text: 'BA', value: 'BA' },
  { text: 'BAcc', value: 'BAcc' },
  { text: 'BAE', value: 'BAE' },
  { text: 'BAppSc', value: 'BAppSc' },
  { text: 'BArch', value: 'BArch' },
  { text: 'BASc', value: 'BASc' },
  { text: 'BASS', value: 'BASS' },
  { text: 'BBA', value: 'BBA' },
  { text: 'BChD', value: 'BChD' },
  { text: 'BCJ', value: 'BCJ' },
  { text: 'BCL', value: 'BCL' },
  { text: 'BCom', value: 'BCom' },
  { text: 'BComm', value: 'BComm' },
  { text: 'BComp', value: 'BComp' },
  { text: 'BCoun', value: 'BCoun' },
  { text: 'BD', value: 'BD' },
  { text: 'BDes', value: 'BDes' },
  { text: 'BDS', value: 'BDS' },
  { text: 'BEcon&Fin', value: 'BEcon&Fin' },
  { text: 'BEcon', value: 'BEcon' },
  { text: 'BEd', value: 'BEd' },
  { text: 'BEng', value: 'BEng' },
  { text: 'BFA', value: 'BFA' },
  { text: 'BFin', value: 'BFin' },
  { text: 'BHSc', value: 'BHSc' },
  { text: 'BLitt', value: 'BLitt' },
  { text: 'BM', value: 'BM' },
  { text: 'BMBCh', value: 'BMBCh' },
  { text: 'BMBS', value: 'BMBS' },
  { text: 'BMedSch', value: 'BMedSch' },
  { text: 'BMetVed', value: 'BMetVed' },
  { text: 'BMid', value: 'BMid' },
  { text: 'BMin', value: 'BMin' },
  { text: 'BMS', value: 'BMS' },
  { text: 'BMSc', value: 'BMSc' },
  { text: 'BMus', value: 'BMus' },
  { text: 'BNurs', value: 'BNurs' },
  { text: 'BOptom', value: 'BOptom' },
  { text: 'BPharm', value: 'BPharm' },
  { text: 'BPhil', value: 'BPhil' },
  { text: 'BPhys', value: 'BPhys' },
  { text: 'BS', value: 'BS' },
  { text: 'BSc (Econ)', value: 'BSc (Econ)' },
  { text: 'BSc (Eng)', value: 'BSc (Eng)' },
  { text: 'BSc (Psych)', value: 'BSc (Psych)' },
  { text: 'BSc', value: 'BSc' },
  { text: 'BSocSc', value: 'BSocSc' },
  { text: 'BTchg', value: 'BTchg' },
  { text: 'BTEC', value: 'BTEC' },
  { text: 'BTech', value: 'BTech' },
  { text: 'BTh', value: 'BTh' },
  { text: 'BVetM', value: 'BVetM' },
  { text: 'BVetMed', value: 'BVetMed' },
  { text: 'BVM & S', value: 'BVM & S' },
  { text: 'BVM&S', value: 'BVM&S' },
  { text: 'BVMedSc', value: 'BVMedSc' },
  { text: 'BVS', value: 'BVS' },
  { text: 'BVSc', value: 'BVSc' },
  { text: 'CIM', value: 'CIM' },
  { text: 'DBA', value: 'DBA' },
  { text: 'DCL', value: 'DCL' },
  { text: 'DClinPsych', value: 'DClinPsych' },
  { text: 'DCounsPych', value: 'DCounsPych' },
  { text: 'DD', value: 'DD' },
  { text: 'DDS', value: 'DDS' },
  { text: 'DEd', value: 'DEd' },
  { text: 'DEdChPsy', value: 'DEdChPsy' },
  { text: 'DEng', value: 'DEng' },
  { text: 'DForensPsy', value: 'DForensPsy' },
  { text: 'DHealthPsy', value: 'DHealthPsy' },
  { text: 'DHSc', value: 'DHSc' },
  { text: 'DHy', value: 'DHy' },
  { text: 'DLit', value: 'DLit' },
  { text: 'DLitt', value: 'DLitt' },
  { text: 'DM', value: 'DM' },
  { text: 'DMin', value: 'DMin' },
  { text: 'DMus', value: 'DMus' },
  { text: 'DNursSc', value: 'DNursSc' },
  { text: 'DPhil', value: 'DPhil' },
  { text: 'DProf', value: 'DProf' },
  { text: 'DPT', value: 'DPT' },
  { text: 'DSc', value: 'DSc' },
  { text: 'DTech', value: 'DTech' },
  { text: 'DThM', value: 'DThM' },
  { text: 'DUniv', value: 'DUniv' },
  { text: 'EdChPsychD', value: 'EdChPsychD' },
  { text: 'EdD', value: 'EdD' },
  { text: 'EngD', value: 'EngD' },
  { text: 'GDL', value: 'GDL' },
  { text: 'HND', value: 'HND' },
  { text: 'HScD', value: 'HScD' },
  { text: 'JD', value: 'JD' },
  { text: 'LittD', value: 'LittD' },
  { text: 'LLB', value: 'LLB' },
  { text: 'LLD', value: 'LLD' },
  { text: 'LLM', value: 'LLM' },
  { text: 'LPC', value: 'LPC' },
  { text: 'MA', value: 'MA' },
  { text: 'MAcc', value: 'MAcc' },
  { text: 'MAnth', value: 'MAnth' },
  { text: 'MArch', value: 'MArch' },
  { text: 'MArchD', value: 'MArchD' },
  { text: 'MArt', value: 'MArt' },
  { text: 'MASt', value: 'MASt' },
  { text: 'MB', value: 'MB' },
  { text: 'MBA', value: 'MBA' },
  { text: 'MBBS', value: 'MBBS' },
  { text: 'MBChB', value: 'MBChB' },
  { text: 'MBiochem', value: 'MBiochem' },
  { text: 'MBiol', value: 'MBiol' },
  { text: 'MBiolSc', value: 'MBiolSc' },
  { text: 'MBiolSci', value: 'MBiolSci' },
  { text: 'MBM', value: 'MBM' },
  { text: 'MBus', value: 'MBus' },
  { text: 'MCD', value: 'MCD' },
  { text: 'MCh', value: 'MCh' },
  { text: 'MChem', value: 'MChem' },
  { text: 'MChemPhys', value: 'MChemPhys' },
  { text: 'MChiro', value: 'MChiro' },
  { text: 'MCL', value: 'MCL' },
  { text: 'MClinDent', value: 'MClinDent' },
  { text: 'MComp', value: 'MComp' },
  { text: 'MD', value: 'MD' },
  { text: 'MDes', value: 'MDes' },
  { text: 'MDiv', value: 'MDiv' },
  { text: 'MDrama', value: 'MDrama' },
  { text: 'MEarthSci', value: 'MEarthSci' },
  { text: 'MEcon', value: 'MEcon' },
  { text: 'MEd', value: 'MEd' },
  { text: 'MEng', value: 'MEng' },
  { text: 'MEnvSc', value: 'MEnvSc' },
  { text: 'MFA', value: 'MFA' },
  { text: 'MGeog', value: 'MGeog' },
  { text: 'MGeol', value: 'MGeol' },
  { text: 'MGeophys', value: 'MGeophys' },
  { text: 'MiF', value: 'MiF' },
  { text: 'MiM', value: 'MiM' },
  { text: 'MInf', value: 'MInf' },
  { text: 'MJur', value: 'MJur' },
  { text: 'MLib', value: 'MLib' },
  { text: 'MLitt', value: 'MLitt' },
  { text: 'MMark', value: 'MMark' },
  { text: 'MMath', value: 'MMath' },
  { text: 'MMathComp', value: 'MMathComp' },
  { text: 'MMathCompSci', value: 'MMathCompSci' },
  { text: 'MMathPhil', value: 'MMathPhil' },
  { text: 'MMathPhys', value: 'MMathPhys' },
  { text: 'MMathStat', value: 'MMathStat' },
  { text: 'MMet', value: 'MMet' },
  { text: 'MMORSE', value: 'MMORSE' },
  { text: 'MMS', value: 'MMS' },
  { text: 'MMus', value: 'MMus' },
  { text: 'MNatSc', value: 'MNatSc' },
  { text: 'MNursSc', value: 'MNursSc' },
  { text: 'MOcean', value: 'MOcean' },
  { text: 'MOptom', value: 'MOptom' },
  { text: 'MOst', value: 'MOst' },
  { text: 'MPA', value: 'MPA' },
  { text: 'MPH', value: 'MPH' },
  { text: 'MPharm', value: 'MPharm' },
  { text: 'MPhil', value: 'MPhil' },
  { text: 'MPhys', value: 'MPhys' },
  { text: 'MPhysPhil', value: 'MPhysPhil' },
  { text: 'MPlan', value: 'MPlan' },
  { text: 'MPP', value: 'MPP' },
  { text: 'MProf', value: 'MProf' },
  { text: 'MProfSt', value: 'MProfSt' },
  { text: 'MPS', value: 'MPS' },
  { text: 'MRes', value: 'MRes' },
  { text: 'MSc', value: 'MSc' },
  { text: 'MSci', value: 'MSci' },
  { text: 'MSocSc', value: 'MSocSc' },
  { text: 'MSSc', value: 'MSSc' },
  { text: 'MSt', value: 'MSt' },
  { text: 'MStat', value: 'MStat' },
  { text: 'MSW', value: 'MSW' },
  { text: 'MTh', value: 'MTh' },
  { text: 'MTheol', value: 'MTheol' },
  { text: 'MTL', value: 'MTL' },
  { text: 'MUniv', value: 'MUniv' },
  { text: 'MusD', value: 'MusD' },
  { text: 'MusM', value: 'MusM' },
  { text: 'PG Dip', value: 'PG Dip' },
  { text: 'PhD', value: 'PhD' },
  { text: 'QCF', value: 'QCF' },
  { text: 'ScD', value: 'ScD' },
  { text: 'SocScD', value: 'SocScD' },
  { text: 'ThD', value: 'ThD' },
  { text: 'VetMB', value: 'VetMB' }
]

export const grades = [
  { text: '1st', value: '1st' },
  { text: '2:1', value: '2:1' },
  { text: '2:2', value: '2:2' },
  { text: '3rd', value: '3rd' },
  { text: 'Pass', value: 'Pass' },
  { text: 'Merit', value: 'Merit' },
  { text: 'Distinction', value: 'Distinction' }
]
