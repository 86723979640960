<template>
  <div class="text-sm">
    <div class="flex justify-between items-center mb-4">
      <p v-if="title">
        {{ title }}
      </p>
      <div
        v-if="metric"
        class="flex justify-between items-center"
      >
        <span class="font-medium">{{ value }}</span>
        <span class="ml-1">{{ metric }}</span>
      </div>
    </div>
    <div class="flex justify-center items-center">
      <div
        class="relative w-full px-2"
      >
        <div>
          <input
            v-model.number="value"
            type="range"
            :min="min"
            :max="max"
            class="absolute appearance-none z-20 h-2 w-full opacity-0 cursor-pointer"
            @input="maxtrigger"
          >

          <div class="relative z-10 h-2">
            <div class="absolute z-10 inset-0 rounded-full bg-gray-200"></div>

            <div
              class="absolute z-20 rounded-full bg-secondary inset-0"
              :style="`width: ${thumbPosition}%`"
            ></div>

            <div
              class="absolute z-30 w-5 h-5 inset-0 bg-secondary rounded-full -ml-2"
              :style="`left: ${thumbPosition}%; top: -6px;`"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  props: {
    title: {
      type: String,
      default: null
    },

    metric: {
      type: String,
      default: null
    },

    max: {
      type: Number,
      default: 0
    },

    savedValue: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      value: this.savedValue ? this.savedValue : 0,
      min: 0,
      thumbPosition: 0
    }
  },

  created() {
    this.triggerChange = debounce(this.triggerChange, 300)
    if (this.savedValue) {
      this.maxtrigger()
    }
  },

  methods: {
    maxtrigger() {
      this.thumbPosition = this.value * 100 / this.max
      this.triggerChange()
    },

    triggerChange(event) {
      this.$emit('onChange', this.value)
    }
  }
}
</script>
