<template>
  <div>
    <form
      novalidate
      @submit.prevent
    >
      <section v-if="showField('photo')">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Photo
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Upload a new profile photo here. This will appear on your dashboard.
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <EditPhoto
              :candidate="candidate"
              :v="$v.formResponses.photo"
              :photo="formResponses.photo"
              @photo="formResponses.photo = $event"
            />
          </div>
        </div>
      </section>

      <section v-if="showField('workHistory')">
        <div
          class="hidden sm:block"
          aria-hidden="true"
        >
          <div class="py-5">
            <div class="border-t border-gray-300"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Work History
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Tell us about your previous and current roles.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <EditWorkHistory
                :work-history="formResponses.workHistory"
                :v="$v.formResponses.workHistory"
                @addWorkHistory="addWorkHistory"
                @deleteWorkHistory="deleteWorkHistory($event)"
                @isCurrentJob="isCurrentJob($event)"
                @chooseJobTitle="chooseJobTitle($event)"
              />
            </div>
          </div>
        </div>
      </section>

      <section v-if="showField('educationHistory')">
        <div
          class="hidden sm:block"
          aria-hidden="true"
        >
          <div class="py-5">
            <div class="border-t border-gray-300"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Education
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  What qualifications do you have? You can add as many as you like.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2 z-30">
              <EditEducationHistory
                :education-history="formResponses.educationHistory"
                :v="$v.formResponses.educationHistory"
                @addEducationHistory="addEducationHistory"
                @deleteEducationHistory="deleteEducationHistory($event)"
                @chooseCourse="chooseCourse($event)"
                @chooseInstitute="chooseInstitute($event)"
              />
            </div>
          </div>
        </div>
      </section>

      <section v-if="showField('roleExperience')">
        <div
          class="hidden sm:block"
          aria-hidden="true"
        >
          <div class="py-5">
            <div class="border-t border-gray-300"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Role Experience
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Let us know how many years experience you have for each role.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <EditRoleExperience
                :v="$v.formResponses.roleExperience"
                @selected="formResponses.roleExperience = $event"
              />
            </div>
          </div>
        </div>
      </section>

      <section v-if="showField('skills')">
        <div
          class="hidden sm:block"
          aria-hidden="true"
        >
          <div class="py-5">
            <div class="border-t border-gray-300"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6 text-sm">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Skills
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  What skills do you have? Add three or more.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <EditSkills
                :v="$v.formResponses.skills"
                @selected="formResponses.skills = $event"
              />
            </div>
          </div>
        </div>
      </section>

      <section v-if="showField('location')">
        <div
          class="hidden sm:block"
          aria-hidden="true"
        >
          <div class="py-5">
            <div class="border-t border-gray-300"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6 text-sm">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Location
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Where do you live?
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div class="bg-white px-4 py-5 sm:p-6 rounded-lg shadow">
                <SearchGoogleLocations
                  :v="$v.formResponses.location"
                  @selected="formResponses.location = $event"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="showField('companyPreference')">
        <div
          class="hidden sm:block"
          aria-hidden="true"
        >
          <div class="py-5">
            <div class="border-t border-gray-300"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6 text-sm">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Company Preferences
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Tell us about your preferred company environment.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div class="shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 bg-white sm:p-6">
                  <div class="grid grid-cols-4 gap-6 text-sm">
                    <div class="col-span-4">
                      <EditCompanyPreference
                        :company-preference="formResponses.companyPreference"
                        :v="$v.formResponses.companyPreference"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="showField('employmentStatus')">
        <div
          class="hidden sm:block"
          aria-hidden="true"
        >
          <div class="py-5">
            <div class="border-t border-gray-300"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6 text-sm">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Employment
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  You can let us know your current employment status here.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div class="shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 bg-white sm:p-6">
                  <div class="grid grid-cols-4 gap-6 text-sm">
                    <div class="col-span-4">
                      <EditEmployment
                        :employment-status="formResponses.employmentStatus"
                        :employment="formResponses.employment"
                        :v="$v.formResponses.employment"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="flex justify-end mt-12">
        <BaseButton
          variant="ghost"
          class="md:w-24 mr-4"
          @click="redirectOnExit"
        >
          Cancel
        </BaseButton>
        <BaseButton
          variant="green"
          class="md:w-24"
          :loading="processing"
          @click="submitProfile"
        >
          Save
        </BaseButton>
      </div>
    </form>

    <ProfileCompleteModal
      :modal-open="profileUpdated"
      @close="profileUpdated = false"
      @modalContentButtonClick="redirectOnExit"
    />
  </div>
</template>

<script>
import EditRoleExperience from '@components/CandidateWizardProfile/EditRoleExperience'
import SearchGoogleLocations from '@components/SearchGoogleLocations'
import EditCompanyPreference from '@components/CandidateWizardProfile/EditCompanyPreference'
import EditEmployment from '@components/CandidateWizardProfile/EditEmployment'
import EditWorkHistory from '@components/CandidateWizardProfile/EditWorkHistory'
import EditEducationHistory from '@components/CandidateWizardProfile/EditEducationHistory'
import EditPhoto from '@components/CandidateWizardProfile/EditPhoto'
import EditSkills from '@components/CandidateWizardProfile/EditSkills'
import ProfileCompleteModal from '@components/CandidateWizardProfile/ProfileCompleteModal'

import candidateWizardApi from '@api/candidateWizard'

import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import omit from 'lodash/omit'

const objectIsEmpty = (object) => Object.values(object).every(x => x.preference === false)

const flatten = require('flat')

export default {
  components: {
    EditRoleExperience,
    SearchGoogleLocations,
    EditCompanyPreference,
    EditEmployment,
    EditWorkHistory,
    EditEducationHistory,
    EditPhoto,
    EditSkills,
    ProfileCompleteModal
  },

  mixins: [validationMixin],

  props: {
    candidate: {
      type: Object,
      default: null
    },
    profile: {
      type: Object,
      default: null
    },
    cv: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      // Validation
      hasErrors: false,
      processing: false,
      profileUpdated: false,

      // Form Responses
      formResponses: {
        photo: null,
        employment: {
          permanent: {
            preference: false,
            minPay: null
          },
          contract: {
            preference: false,
            minPay: null
          },
          internship: {
            preference: false
          }
        },
        companyPreference: {
          size: [],
          culture: []
        },
        workHistory: [],
        educationHistory: [],
        skills: [],
        location: null,
        roleExperience: []
      }
    }
  },

  validations() {
    return {
      formResponses: {
        workHistory: {
          $each: {
            title: {
              required
            },
            employer: {
              required
            },
            current: {},
            start: {
              month: {
                required
              },
              year: {
                required
              }
            },
            end: {
              month: {},
              year: {}
            },

            highlights: {}
          }
        },

        educationHistory: {
          $each: {
            grade: {
              required
            },
            type: {
              required
            },
            course: {
              required
            },
            institute: {
              required
            },
            startYear: {
              required
            },
            endYear: {
              required
            }
          }
        },

        skills: {},

        photo: {},

        location: {},

        roleExperience: {},

        companyPreference: {
          size: {},
          culture: {}
        },

        employment: {
          permanent: {
            preference: {},
            minPay: {}
          },
          contract: {
            preference: {},
            minPay: {}
          },
          internship: {
            preference: {}
          }
        }
      }
    }
  },

  computed: {
    employmentPreferencesEmpty() {
      return objectIsEmpty(this.formResponses.employment)
    },

    job() {
      if (!this.$route.query.job) {
        return
      }
      return this.candidate.jobs.find(job => job.wizardSlug === this.$route.query.job)
    }
  },

  watch: {
    'formResponses.employment': {
      deep: true,
      handler(val) {
        if (!val.permanent.preference) {
          this.formResponses.employment.permanent.minPay = 0
        }
        if (!val.contract.preference) {
          this.formResponses.employment.contract.minPay = 0
        }
      }
    },

    cv(cv) {
      if (cv) {
        const requiredCVFieldsOnly = omit(cv, this.job.hiddenProfileFields)
        this.assignProfile(requiredCVFieldsOnly)
      }
    }
  },

  created() {
    if (this.profile) {
      this.assignProfile(this.profile)
    }
  },

  methods: {
    assignProfile(profile) {
      Object.assign(this.formResponses, profile)

      if (!this.formResponses.educationHistory.length && this.showField('educationHistory')) {
        this.addEducationHistory()
      }

      if (!this.formResponses.workHistory.length && this.showField('workHistory')) {
        this.addWorkHistory()
      }
    },

    checkEmploymentPreferences() {
      const employmentPreferences = Object.values(this.formResponses.employment).map(entry => {
        return entry.preference
      })
      // At least one employment checkbox is required
      return employmentPreferences.every(value => value === false)
    },

    addWorkHistory() {
      this.formResponses.workHistory.push({
        current: false,
        title: null,
        employer: null,
        start: {
          month: null,
          year: null
        },
        end: {
          month: null,
          year: null
        },
        highlights: null
      })
    },

    deleteWorkHistory(workHistoryIndex) {
      this.formResponses.workHistory = this.formResponses.workHistory.filter((_, index) => index !== workHistoryIndex)
    },

    isCurrentJob(event) {
      // Nullify the end year and month
      if (!event.checked) {
        return
      }
      Vue.set(this.formResponses.workHistory[event.index], 'end', {
        month: null,
        year: null
      })
    },

    chooseJobTitle(event) {
      if (!event) {
        return
      }
      Vue.set(this.formResponses.workHistory[event.index], 'title', event.title)
    },

    chooseCourse(event) {
      if (!event) {
        return
      }
      Vue.set(this.formResponses.educationHistory[event.index], 'course', event.course)
    },

    chooseInstitute(event) {
      if (!event) {
        return
      }
      Vue.set(this.formResponses.educationHistory[event.index], 'institute', event.institute)
    },

    addEducationHistory() {
      this.formResponses.educationHistory.push({
        institute: null,
        course: null,
        type: null,
        grade: null,
        startYear: null,
        endYear: null
      })
    },

    deleteEducationHistory(educationIndex) {
      this.formResponses.educationHistory = this.formResponses.educationHistory.filter((_, index) => index !== educationIndex)
    },

    redirectOnExit() {
      this.$router.push({ name: 'candidate-wizard-dashboard' })
    },

    checkWorkAndEducationHistories(profile) {
      let checkedProfile = {}

      const workHistory = flatten(profile.workHistory)
      const educationHistory = flatten(profile.educationHistory)

      const workHistoryIsEmpty = Object.values(workHistory).every(item => item === null || item === false)
      const educationHistoryIsEmpty = Object.values(educationHistory).every(item => item === null || item === false)

      // If workHistory and educationHistory are empty return empty array
      checkedProfile = {
        ...profile,
        workHistory: workHistoryIsEmpty ? [] : profile.workHistory,
        educationHistory: educationHistoryIsEmpty ? [] : profile.educationHistory
      }

      return checkedProfile
    },

    showField(field) {
      if (!this.job) {
        return true
      }
      return !this.job.hiddenProfileFields.includes(field)
    },

    submitProfile() {
      this.processing = true

      this.$v.formResponses.$touch()
      this.hasErrors = this.$v.formResponses.$anyError

      if (this.hasErrors) {
        this.$nextTick(() => this.focusFirstError())
        this.processing = false
        return
      }

      const profile = this.checkWorkAndEducationHistories(this.formResponses)

      return candidateWizardApi.submitProfile(profile)
        .then(response => {
          this.processing = false
          this.profileUpdated = true
        })
        .catch(error => {
          this.processing = false
          throw error
        })
    },

    focusFirstError(component = this) {
      const componentsWithErrors = component.$children.map(component => {
        return {
          ...component,
          hasError: component.v ? component.v.$anyError : false
        }
      })

      const formInputs = componentsWithErrors.find(component => (component.$refs !== {} && component.hasError))

      if (formInputs) {
        formInputs.$el.scrollIntoView()
      }
    }

  }
}
</script>
