<template>
  <div
    ref="photo"
    class="shadow sm:rounded-lg sm:overflow-hidden"
  >
    <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
      <div>
        <label class="block text-sm font-medium text-gray-700 sr-only">
          Photo
        </label>
        <div class="flex items-center">
          <div
            v-if="!photoUrl && processing"
            class="relative h-16 w-16 flex flex-col justify-center"
          >
            <Loader />
          </div>
          <span
            v-if="!processing && !photoUrl"
            class="inline-block h-16 w-16 rounded-full overflow-hidden bg-gray-100"
          >
            <svg
              class="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
          <div
            v-if="photoUrl"
            class="flex-shrink-0"
          >
            <img
              class="mx-auto h-16 w-16 rounded-full"
              :src="photoUrl"
              alt="Profile Photo"
            >
          </div>
          <label
            for="photo-upload"
            class="duration-150 rounded bg-transparent hover:text-gray-700 border border-gray-500 px-4 py-2 cursor-pointer text-sm ml-6"
          >
            <span v-if="photoUrl">Change</span>
            <span v-else>Add photo</span>

            <input
              id="photo-upload"
              type="file"
              accept="image/*"
              :disabled="processing"
              class="sr-only"
              @click="uploadError = ''"
              @change="uploadFiles($event.target.files[0])"
            >
          </label>
          <ErrorsInline
            v-if="v.$anyError"
            class="relative ml-4"
          >
            <span v-if="!v.required">Please add a photo</span>
          </ErrorsInline>

          <ErrorsInline
            v-if="uploadError"
            class="relative ml-4"
          >
            <span>{{ uploadError }}</span>
          </ErrorsInline>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import candidateWizardApi from '@api/candidateWizard'
import ErrorsInline from '@components/ErrorsInline'
import Loader from '@components/Loader'

export default {
  components: {
    ErrorsInline,
    Loader
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },
    v: {
      type: Object,
      default: null
    },
    photo: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      cloudinaryCloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
      cloudinaryApiKey: process.env.VUE_APP_CLOUDINARY_API_KEY,
      processing: false,
      photoUrl: null,
      uploadError: ''
    }
  },

  watch: {
    photo: {
      immediate: true,
      handler(photo) {
        if (photo) {
          this.photoUrl = `https://res.cloudinary.com/${this.cloudinaryCloudName}/image/upload/w_100,h_100,c_fill/v${this.photo.version}/${this.photo.publicId}.${this.photo.format}`
        }
      }
    }
  },

  methods: {
    uploadFiles(file) {
      this.photoUrl = null
      this.processing = true

      const imageData = new FormData()

      let publicId = this.candidate.uuid
      let timestamp = Math.round(new Date().getTime() / 1000)
      const uploadPreset = 'candidate_profile_photos'

      imageData.append('upload_preset', uploadPreset)
      imageData.append('public_id', publicId)
      imageData.append('file', file)
      imageData.append('api_key', this.cloudinaryApiKey)
      imageData.append('timestamp', timestamp)

      return candidateWizardApi.getCloudinarySignature({ publicId, timestamp, uploadPreset })
        .then(response => {
          imageData.append('signature', response.signature)

          candidateWizardApi.uploadImage(imageData, this.cloudinaryCloudName)
            .then(image => {
              this.processing = false

              this.$emit('photo', {
                publicId: image.public_id,
                version: image.version,
                format: image.format,
                cloudName: this.cloudinaryCloudName
              })
            })
            .catch(error => {
              this.uploadError = 'Oops! Something went wrong. Please try again.'
              this.processing = false
              throw error
            })
        })
        .catch(error => {
          this.processing = false
          throw error
        })
    }
  }
}
</script>
