<template>
  <div
    ref="companyPreference"
    class="space-y-8"
  >
    <fieldset>
      <legend class="mb-4 font-medium text-gray-700">
        What is your preferred company size?
      </legend>
      <div class="grid grid-cols-3 gap-4">
        <label class="relative block rounded-lg border bg-white shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus:border-secondary text-center border-gray-300 hover:border-gray-400">
          <input
            v-model="v.size.$model"
            type="checkbox"
            name="Small"
            value="Small"
            class="sr-only"
            aria-labelledby="Small-label"
            aria-describedby="Small-option"
          >
          <div class="flex items-center justify-center">
            <p
              id="Small-label"
              class="font-medium text-sm"
            >
              0 - 25
            </p>
          </div>
          <div
            :class="['absolute inset-0 rounded-lg border-2 pointer-events-none', v.size.$model.includes('Small') ? 'border-secondary' : 'border-transparent']"
            aria-hidden="true"
          ></div>
        </label>

        <label class="relative block rounded-lg border bg-white shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus:border-secondary text-center border-gray-300 hover:border-gray-400">
          <input
            v-model="v.size.$model"
            type="checkbox"
            name="Medium"
            value="Medium"
            class="sr-only"
            aria-labelledby="Medium-label"
            aria-describedby="Medium-option"
          >
          <div class="flex items-center justify-center">
            <p
              id="Medium-label"
              class="font-medium text-sm"
            >
              25 - 100
            </p>
          </div>
          <div
            :class="['absolute inset-0 rounded-lg border-2 pointer-events-none', v.size.$model.includes('Medium') ? 'border-secondary' : 'border-transparent']"
            aria-hidden="true"
          ></div>
        </label>

        <label class="relative block rounded-lg border bg-white shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus:border-secondary text-center border-gray-300 hover:border-gray-400">
          <input
            v-model="v.size.$model"
            type="checkbox"
            name="Large"
            value="Large"
            class="sr-only"
            aria-labelledby="Large-label"
            aria-describedby="Large-option"
          >
          <div class="flex items-center justify-center">
            <p
              id="Large-label"
              class="font-medium text-sm"
            >
              100+
            </p>
          </div>
          <div
            :class="['absolute inset-0 rounded-lg border-2 pointer-events-none', v.size.$model.includes('Large') ? 'border-secondary' : 'border-transparent']"
            aria-hidden="true"
          ></div>
        </label>
      </div>
    </fieldset>

    <fieldset>
      <legend class="mb-4 font-medium text-gray-700">
        What is your preferred company culture?
      </legend>
      <div class="grid grid-cols-3 gap-4">
        <label class="relative block rounded-lg border bg-white shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus:border-secondary text-center border-gray-300 hover:border-gray-400">
          <input
            v-model="v.culture.$model"
            type="checkbox"
            name="Corporate"
            value="Corporate"
            class="sr-only"
            aria-labelledby="Corporate-label"
            aria-describedby="Corporate-option"
          >
          <div class="flex items-center justify-center">
            <p
              id="Corporate-label"
              class="font-medium text-sm"
            >
              Corporate
            </p>
          </div>
          <div
            :class="['absolute inset-0 rounded-lg border-2 pointer-events-none', v.culture.$model.includes('Corporate') ? 'border-secondary' : 'border-transparent']"
            aria-hidden="true"
          ></div>
        </label>

        <label class="relative block rounded-lg border bg-white shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus:border-secondary text-center border-gray-300 hover:border-gray-400">
          <input
            v-model="v.culture.$model"
            type="checkbox"
            name="Creative"
            value="Creative"
            class="sr-only"
            aria-labelledby="Creative-label"
            aria-describedby="Creative-option"
          >
          <div class="flex items-center justify-center">
            <p
              id="Creative-label"
              class="font-medium text-sm"
            >
              Creative
            </p>
          </div>
          <div
            :class="['absolute inset-0 rounded-lg border-2 pointer-events-none', v.culture.$model.includes('Creative') ? 'border-secondary' : 'border-transparent']"
            aria-hidden="true"
          ></div>
        </label>

        <label class="relative block rounded-lg border bg-white shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus:border-secondary text-center border-gray-300 hover:border-gray-400">
          <input
            v-model="v.culture.$model"
            type="checkbox"
            name="Startup"
            value="Startup"
            class="sr-only"
            aria-labelledby="Startup-label"
            aria-describedby="Startup-option"
          >
          <div class="flex items-center justify-center">
            <p
              id="Startup-label"
              class="font-medium text-sm"
            >
              Startup
            </p>
          </div>
          <div
            :class="['absolute inset-0 rounded-lg border-2 pointer-events-none', v.culture.$model.includes('Startup') ? 'border-secondary' : 'border-transparent']"
            aria-hidden="true"
          ></div>
        </label>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: {
    companyPreference: {
      type: Object,
      default: null
    },
    v: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      sizes: [],
      cultures: []
    }
  }
}
</script>
