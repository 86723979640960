export const salaries = [
  { text: '10,000', value: '10000' },
  { text: '11,000', value: '11000' },
  { text: '12,000', value: '12000' },
  { text: '13,000', value: '13000' },
  { text: '14,000', value: '14000' },
  { text: '15,000', value: '15000' },
  { text: '16,000', value: '16000' },
  { text: '17,000', value: '17000' },
  { text: '18,000', value: '18000' },
  { text: '19,000', value: '19000' },
  { text: '20,000', value: '20000' },
  { text: '21,000', value: '21000' },
  { text: '22,000', value: '22000' },
  { text: '23,000', value: '23000' },
  { text: '24,000', value: '24000' },
  { text: '25,000', value: '25000' },
  { text: '26,000', value: '26000' },
  { text: '27,000', value: '27000' },
  { text: '28,000', value: '28000' },
  { text: '29,000', value: '29000' },
  { text: '30,000', value: '30000' },
  { text: '31,000', value: '31000' },
  { text: '32,000', value: '32000' },
  { text: '33,000', value: '33000' },
  { text: '34,000', value: '34000' },
  { text: '35,000', value: '35000' },
  { text: '36,000', value: '36000' },
  { text: '37,000', value: '37000' },
  { text: '38,000', value: '38000' },
  { text: '39,000', value: '39000' },
  { text: '40,000', value: '40000' },
  { text: '41,000', value: '41000' },
  { text: '42,000', value: '42000' },
  { text: '43,000', value: '43000' },
  { text: '44,000', value: '44000' },
  { text: '45,000', value: '45000' },
  { text: '46,000', value: '46000' },
  { text: '47,000', value: '47000' },
  { text: '48,000', value: '48000' },
  { text: '49,000', value: '49000' },
  { text: '50,000', value: '50000' },
  { text: '55,000', value: '55000' },
  { text: '60,000', value: '60000' },
  { text: '65,000', value: '65000' },
  { text: '70,000', value: '70000' },
  { text: '75,000', value: '75000' },
  { text: '80,000', value: '80000' },
  { text: '85,000', value: '85000' },
  { text: '90,000', value: '90000' },
  { text: '95,000', value: '95000' },
  { text: '100,000+', value: '100000' }
]

export const dayRates = [
  { text: '100', value: '100' },
  { text: '150', value: '150' },
  { text: '200', value: '200' },
  { text: '250', value: '250' },
  { text: '300', value: '300' },
  { text: '350', value: '350' },
  { text: '400', value: '400' },
  { text: '500+', value: '500' }
]
